import axios from 'axios'
import config from '../config'

let proforma = {
    fetchLogs : (ctx) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/logs/fetch", data: {ctx : ctx }, method: 'POST' })
            .then(resp => {
                resolve({ items : resp.data.responseData.logs , totalCount : resp.data.responseData.count});
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    clearLogs : (ctx) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/logs/clear", data: {ctx : ctx }, method: 'POST' })
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    fetchActionLogs : (ctx) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/logs/actionFetch", data: {ctx : ctx }, method: 'POST' })
            .then(resp => {
                resolve({ items : resp.data.responseData.logs , totalCount : resp.data.responseData.count});
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    fetchActions: () => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/logs/actionFetchNames", data: { }, method: 'POST' })
            .then(resp => {
                resolve({ items : resp.data.responseData.actions});
            })
            .catch(err => {
                reject(err);
            })
        });
    },


}

export default proforma;
