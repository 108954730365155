<template>
  <v-container class="map_container" justify-center id="map">
    <v-layout justify-center>
      <GmapMap
        ref="mapRef"
        v-if="mapReady"
        :center="{ lat: 41.8548271, lng: 12.4545949 }"
        :zoom="16"
        map-type-id="roadmap"
        class="map_style"
      >
        <GmapMarker
          :position="{ lat: 41.854593, lng: 12.456442 }"
          :label="{
            text: 'Laboratorio Roscioli',
            fontSize: '14px',
            className: 'lab_marker_position',
            color: 'black',
          }"
          :animation="google.maps.Animation.DROP"
          :icon="{ url: home_icon, scaledSize: new google.maps.Size(48, 48) }"
        />

        <GmapMarker
          :position="{ lat: 41.8945667, lng: 12.4742778 }"
          :label="{
            text: 'Forno Roscioli',
            fontSize: '14px',
            className: 'lab_marker_position',
            color: 'black',
          }"
          :animation="google.maps.Animation.DROP"
          :icon="{ url: home_icon, scaledSize: new google.maps.Size(48, 48) }"
        />

        <GmapMarker
          v-bind:key="item.id"
          v-for="item in couriers"
          ref="couriersMarkers"
          :position="{ lat: item.latitude, lng: item.longitude }"
          :label="{
            text: labelTextForItem(item),
            fontSize: '14px',
            className: 'car_position',
            color: 'white',
          }"
          :animation="google.maps.Animation.DROP"
          @click="openInfoWindow(item)"
          :clickable="true"
          :icon="{ url: car_icon, scaledSize: new google.maps.Size(48, 48) }"
        />
        <gmap-info-window
          v-bind:key="'iw' + item.id"
          v-for="item in couriers"
          ref="infoWindow"
          :options="{
            maxWidth: 300,
            pixelOffset: { width: 0, height: -35 },
          }"
          :position="{ lat: item.latitude, lng: item.longitude }"
          :opened="item.iwopen"
          @closeclick="item.iwopen = false"
        >
          <div v-html="infoWindowTextForItem(item)"></div>
        </gmap-info-window>
      </GmapMap>
    </v-layout>
  </v-container>
</template>

<style>
.map_style {
  width: 100%;
  min-height: 80vh;
}

.map_container {
  border: 1px solid black;
  min-width: 95vw;
  margin: auto;
}

.car_position {
  bottom: 40px;
  border: 1px solid black;
  padding: 5px;
  background-color: black;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.lab_marker_position {
  bottom: 40px;
  border: 1px solid black;
  padding: 5px;
  background-color: white;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
</style>

<script>
import { gmapApi } from "vue2-google-maps";
import usersManager from "../apis/users";
import config from "../config";

export default {
  computed: {
    google: gmapApi,
  },

  data: function() {
    return {
      mapReady: false,
      home_icon: require("../assets/gm_home.svg"),
      car_icon: require("../assets/gm_car.svg"),
      couriers: [],
      id_to_courier: {},
      events: null,
    };
  },

  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.mapReady = true;
      this.fetchCouriers().then(() => {
        this.setupServerSSE();
      });
    });
  },

  beforeDestroy() {
    if (this.events) {
      this.events.close();
      this.events = null;
    }
  },

  methods: {
    setupServerSSE() {
      if (this.events) {
        this.events.close();
        this.events = null;
      }

      this.events = new EventSource(config.apiEndPoint + "/mobile/geoEvents");
      this.events.addEventListener("message", (event) => {
        const locationData = JSON.parse(event.data);
        if (this.id_to_courier[locationData.userId]) {
          let item = this.id_to_courier[locationData.userId];
          item.latitude = locationData.loc.coords.latitude;
          item.longitude = locationData.loc.coords.longitude;
          item.speed = locationData.loc.coords.speed;

          let ref = this.$refs.couriersMarkers[item.index];
          ref.position = { lat: item.latitude, lng: item.longitude };
        }
      });
      this.events.addEventListener("error", (/*err*/) => {
        //console.log(err);
        this.setupServerSSE();
      });
    },

    openInfoWindow(item) {
      item.iwopen = true;
    },

    infoWindowTextForItem(item) {
      return (
        "Velocità: " +
        (parseFloat(item.speed) * 3.6).toFixed(2) +
        " Km/h<BR>Ultima rilevazione: " +
        this.$options.filters.toLocaleDateTime(item.event_time)
      );
    },

    labelTextForItem(item) {
      return item.first_name + " " + item.last_name;
    },

    fetchCouriers() {
      return new Promise((resolve, reject) => {
        usersManager
          .fetchCouriersPosition()
          .then((res) => {
            this.couriers = res;
            for (let n = 0; n < this.couriers.length; n++) {
              this.id_to_courier[this.couriers[n].id] = this.couriers[n];
              this.couriers[n].index = n;
            }

            let bounds = new this.google.maps.LatLngBounds(
              { lat: 41.8548271, lng: 12.4545949 },
              { lat: 41.8945667, lng: 12.4742778 }
            );
            for (let x in this.couriers) {
              this.$set(this.couriers[x], "iwopen", false);
              if (
                this.couriers[x].latitude > 0 &&
                this.couriers[x].longitude > 0
              ) {
                bounds.extend({
                  lat: this.couriers[x].latitude,
                  lng: this.couriers[x].longitude,
                });
              }
            }
            this.$refs.mapRef.fitBounds(bounds);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
  },
};
</script>
